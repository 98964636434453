const axios = require('axios');
const readCookie = require('../readCookie');
const logger = require('../createLogger')('invokeIfcSemantics.js');

module.exports = async function(copyToEfsResponseData) {
    logger.info(`calculateSemantics ${JSON.stringify(copyToEfsResponseData)}`);

    const idToken = readCookie('id_token');

    if (!idToken) {
        logger.info('could not calculate semantics since no user is signed in');
        return;
    }

    // TODO: hide internal paths
    const queryString = `fileName=${copyToEfsResponseData.path}/${copyToEfsResponseData.fileName}`;
    const authorizationValue = `Bearer ${idToken}`;
    logger.info(authorizationValue);

    const response = await axios({
        method: 'GET',
        url: `https://${process.env.VUE_APP_HOST_PUBLIC_API}/api/v1/ifc/semantics?${queryString}`,
        headers: {
            'Content-type': 'application/json',
            Authorization: authorizationValue,
        },
    });

    logger.info(response);

    return response.data;
}
