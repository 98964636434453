<template>
    <div class="header_space">
        <div v-if="loadingStatus === 'pending'" class="loading_image">
            <h1 class="main_font">
                {{ loadingText }}
            </h1>
            <img src="../assets/loading.gif" />
        </div>
        <div v-else-if="loadingStatus === 'done'" class="">
            <pre style="text-align: left;">{{ semantics }}</pre>
        </div>
    </div>
</template>

<script>
import readCookie from '../util/readCookie';
import invokeS3ToEfs from '../util/api/invokeS3ToEfs';
import invokeIfcSemantics from '../util/api/invokeIfcSemantics';

const log = (message) => console.log('Semantics:', message);

function setHeader() {
    this.$root.$emit('set_header_title', this.header);
}

export default {
    props: {
        objectKey: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            loadingStatus: null,
            loadingText: '',
            semantics: {},
            header: {
                title: 'Semantics',
            },
        };
    },

    async mounted() {
        log('semantics');
        this.setHeader();

        const idToken = readCookie('id_token');

        if (!idToken) {
            log('not signed in');
            return;
        }

        this.loadingStatus = 'pending';

        this.loadingText = 'preparing your files...';
        const copyToEfsResponseData = await invokeS3ToEfs({ objectKey: this.objectKey, authorizationValue: `Bearer ${idToken}` });

        this.loadingText = 'calculating semantics...';
        const calculateSemanticsResponseData = await invokeIfcSemantics(
            copyToEfsResponseData.data,
        );
        this.semantics = JSON.stringify(
            calculateSemanticsResponseData,
            null,
            4,
        );

        this.loadingStatus = 'done';
    },

    methods: {
        setHeader,
    },
};
</script>

<style scoped></style>
